import React, { useEffect } from "react";
import styles from "./style.module.css";
import './style.module.css'
// import FooterLogo from "./../../assets/home/footer-logo.svg";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import payMe from './pay.png'
import click from './click.png'
const Footer = () => {
  const { t } = useTranslation();


  return (
<>
<div className={styles.WrapperBody}>
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <Row>
            <Col lg={4} md={12}>
              <h2>SUPERTOUR.UZ </h2>
              <div className={styles.Network}>
              <a target={"_blank"} href="https://t.me/Supersite_uz"><i className='bx bxl-telegram'></i></a>
              <a target={"_blank"} href="https://instagram.com/mard__travel?igshid=MzRlODBiNWFlZA=="><i className='bx bxl-instagram' ></i></a>
              <a target={"_blank"} href="https://www.facebook.com/search/top?q=mard%20travel"><i className='bx bxl-facebook-square' ></i></a>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <ul>
                <li>
                  <NavLink className={styles.link} to={"/tour-category/4"}>
                    <p className={styles.FooterLink}>{t("Header.0")}</p>
                  </NavLink>
                </li>
                <li>
                <NavLink className={styles.link} to={"/about"}>
                    <p className={styles.FooterLink}>{t("Header.1")}</p>
                  </NavLink>

                </li>
                <li>
                  <NavLink className={styles.link} to={"/terms"}>
                    <p className={styles.FooterLink}>{t("Header.2")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/important"}>
                    <p className={styles.FooterLink}>{t("Header.3")}</p>
                  </NavLink>
                </li>
                
                <li>
                  <NavLink className={styles.link} to={"/aksiya"}>
                    <p className={styles.FooterLink}>{t("Header.4")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/blog"}>
                    <p className={styles.FooterLink}>{t("Header.5")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/contact"}>
                    <p className={styles.FooterLink}>{t("Header.6")}</p>
                  </NavLink>
                </li>
              </ul>
            </Col>

            <Col lg={4} md={12}>
              <ul>
                <li>
                    <a href="tel:+99899 810-70-90" className={styles.FooterLinkHead}><i className='bx bx-phone'  ></i>  +99899 810-70-90</a>
                </li>
                <li>
                    <a href="tel:+99899 814-70-90" className={styles.FooterLinkHead}><i className='bx bx-phone'  ></i>  +99899 814-70-90</a>
                </li>
                
                <li>
                    <a href="mail:tireuzb@mail.ru" className={styles.FooterLinkHead}><i className='bx bx-comment-dots' ></i>Info@super-tour.uz</a>
                </li>
                <li>
                    <a href="" className={styles.FooterLinkHead}><i className='bx bxs-map'></i>{t("Contact.2")} </a>
                </li>
              </ul>
            </Col>
          </Row>

        </div>
      </div>
      <div className={styles.NetworkMedia}>
              <a target={"_blank"} href="https://t.me/supersiteuz"><i className='bx bxl-telegram'></i></a>
              <a target={"_blank"} href="https://www.instagram.com/supertour_uz/?igshid=NTc4MTIwNjQ2YQ%3D%3D"><i className='bx bxl-instagram' ></i></a>
              </div>
    </div>

</>
  );
};

export default Footer;

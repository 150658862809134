import React, { useEffect, useState } from 'react'
import styles from "./style.module.css"
import Logo from "./../../assets/Headers/logo.svg"
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import LanguageHeader from './header-language'
import HamburgerMenu from './hamburger-menu'
import { useDispatch, useSelector } from 'react-redux'
// import { Catalog, CatalogId } from '../../redux/catalog'
import "./style.css"
const Header = () => {
    const {t} = useTranslation();
    const [HamburgerClick, setHamburgerClick] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
//     useEffect(() => {
//         dispatch(Catalog());
//   }, [])
//   const CatalogGet = useSelector(state => state.Catalog.Catalog.Data)
    const HandleClick = () => {
      setHamburgerClick(true);
    };
    const HandleClickClose = () => {
      setHamburgerClick(false);
    };
    const HandleClickMore =   (e) => {
         navigate(`/products/${e.target.id}`);
         window.localStorage.setItem("CategoryId" , e.target.id)
      };
      function LanguValue() {
        return window.localStorage.getItem("i18nextLng");
      }
  return (
    <>
        <div className={styles.BodyWrapper}>
        <div className={styles.Container}>
        <div className={styles.Wrapper}>
        <NavLink className={styles.link} to={"/"}>
        <img src={Logo} width={165} height={60} alt="logo" />
        </NavLink>
        <ul>
            <li> 
                <span onClick={()=>{
                    navigate("/tour-category/4")
                    window.location.reload()
                }} className={styles.link} >
                    <p>
                {t("Header.0")}
                    </p>
                </span>
            </li>
            <li>
                <NavLink className={styles.link} to={"/about"}>
                    <p>
                {t("Header.1")}
                    </p>
                </NavLink>
            </li>
            <li>
                <NavLink className={styles.link} to={"/terms"}>
                    <p>
                {t("Header.2")}
                    </p>
                </NavLink>
            </li>
            <li> 
            <NavLink className={styles.link} to={"/important"}>
                    <p>
                {t("Header.3")}
                    </p>
                </NavLink>
            </li>
            <li>
                <NavLink className={styles.link} to={"/aksiya"}>
                    <p>
                {t("Header.4")}
                    </p>
                </NavLink>
            </li>
            <li>
                <NavLink className={styles.link} to={"/blog"}>
                    <p>
                {t("Header.5")}
                    </p>
                </NavLink>
            </li>
            <li>
                <NavLink className={styles.link} to={"/contact"}>
                    <p>
                {t("Header.6")}
                    </p>
                </NavLink>
            </li>
        </ul>
        <i class='bx bx-menu-alt-right'  onClick={HandleClick} ></i>
        <div className={styles.Language}>
        <LanguageHeader/>
        </div>
        </div>
    </div>
    </div>
    <HamburgerMenu
        HamburgerClick={HamburgerClick}
        HandleClickClose={HandleClickClose}
      />
    </>
  )
}

export default Header
import { lazy } from "react";
import NotFound from "../components/not_found";

const Home = lazy(() => import("../pages/Home/index"))
const TourCategory = lazy(() => import("../pages/tour_category/index"))
const About = lazy(() => import("../pages/about/index"))
const TourMore = lazy(() => import("../pages/tour_more/index.jsx"))
const Contact = lazy(() => import("../pages/contact/index"))
const Blog = lazy(() => import("../pages/blog/index"))
const Terms = lazy(() => import("../pages/terms/index"))
const Important = lazy(() => import("../pages/important/index"))
const Aksiya = lazy(() => import("../pages/aksiya/index.jsx"))
export const RouterData = [
    {
        id : 1,
        path : "/",
        component : <Home/>
    },
    {
        id : 1,
        path : "/tour-category/:id",
        component : <TourCategory/>
    },
    {
        id : 1,
        path : "/tour-more/:id",
        component : <TourMore/>
    },
    {
        id : 1,
        path : "/contact",
        component : <Contact/>
    },
    {
        id : 1,
        path : "/about",
        component : <About/>
    },
    {
        id : 2 ,
        path :"/terms",
        component : <Terms/>
    },
    {
        id : 2 ,
        path :"/blog",
        component : <Blog/>
    },
    {
        id : 2 ,
        path :"/important",
        component : <Important/>
    },
    {
        id:2,
        path: "/*",
        component : <NotFound/>
    },
    {
        id:2,
        path: "/aksiya",
        component : <Aksiya/>
    }
]